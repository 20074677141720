<template>
  <div class="detected">
    <div class="modal__body detected__body">
      <div class="detected__title">
        {{ title }}
      </div>
      <div class="detected__text">
        {{ text }}
      </div>
      <div class="detected__divider" />
    </div>
    <div class="detected__footer">
      <button
        class="button button_secondary button_md detected__capitalized-button"
        @click="onContinue"
      >
        Yes - that was my best effort. Continue
      </button>
      <div class="detected__button-divider">or</div>
      <button
        class="button button_secondary button_md detected__capitalized-button"
        @click="onClose"
      >
        No - I'd like to try again
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  MSG_DETECTED_LOW_EFFORT_TITLE,
  MSG_DETECTED_LOW_EFFORT_TEXT,
  STORE_SET_PASSED,
  ROUTE_SUSTAINED_AAH,
} from "@/constants";
import { saveLog } from "@/modules/logger";
export default {
  props: {
    options: { type: Object, required: true },
  },
  data() {
    return {
      title: MSG_DETECTED_LOW_EFFORT_TITLE,
      text: MSG_DETECTED_LOW_EFFORT_TEXT,
    };
  },
  methods: {
    ...mapActions([STORE_SET_PASSED]),
    async onClose() {
      this.options.deleteAction();
      saveLog("audio-quality-popup", {msg: "Try again selected"});
      await this[STORE_SET_PASSED]({ [ROUTE_SUSTAINED_AAH]: false });
      this.$router.push({ name: ROUTE_SUSTAINED_AAH });
      this.$emit("close");
    },
    async onContinue() {
      saveLog("audio-quality-popup", {msg: "Continue selected"});
      this.options.submitAction();
      this.$emit("close");
    },
  },
};
</script>
